.c-article {
    width: 655px;
    margin: 0 auto;
    border-top: 1px solid $black;

    &:last-of-type {
        border-bottom: 1px solid $black;
    }

    &__wrapper {
        padding: 31px 0 64px;

        @include mq('to', 768) {
            padding: 15px 0 33px;
        }

        &--mobile {
            padding: 15px 0 33px;
        }
    }

    &__type {
        color: rgba($black, 0.3);
        padding: 14px 0 16px;
        border-bottom: 1px solid rgba($black, 0.3);
        font-size: 18px;

        @include mq('to', 768) {
            padding: 8px 0;
            font-size: 10px;
        }

        &--mobile {
            padding: 8px 0;
            font-size: 10px;
        }
    }

    &__image {
        width: 223px;

        &--mobile {
            width: 117px;
        }
        
        &::before, &::after {
            display: none;
        }

        img {            
            transition: transform .3s ease-in-out;
        }

        &:hover {
            img {
                opacity: 1;
                // -webkit-animation: flash 1.5s;
                animation: flash 2s;
            }
        }
    }

    &__flex-wrapper {
        width: calc(100% - 223px);

        &--mobile {
            width: calc(100% - 117px);
        }
    }

    &__title {
        max-width: 300px;

        &:hover {
            color: $black !important;
        }

        &::before, &::after {
            display: none;
        }

        @include mq('to', 768) {
            &.c-article__title {
                font-size: 18px !important;
                line-height: 24px !important;
                margin: 0 0 4px 0 !important;
                width: 85%;
            }
        }

        &--mobile {
            font-size: 18px !important;
            line-height: 24px !important;
            margin: 0 0 4px 0 !important;
            width: 85%;
        }
    }

    &__author, &__category {
        @include mq('to', 768) {
            font-size: 10px !important;
            margin: 0 !important;
        }

        &--mobile {
            font-size: 10px !important;
            margin: 0 !important;
        }
    }

    &__category {
        margin-bottom: 10px;
        @include mq('to', 768) {
            margin-bottom: 5px !important;
        }

        &--mobile {
            margin-bottom: 5px !important;
        }
    }

    &__pagination-button {
        margin-top: 91px;
    }

    @include mq('to', 768) {
        width: 100%;

        &__image {           
            width: 117px;               
        }

        &__flex-wrapper {
            width: calc(100% - 117px);
        }
    }

    &--mobile {
        width: 100%;
    }
}

@keyframes flash {
	0% {
		opacity: .9;
	}
    5% {
        opacity: 0.6;
    }
	100% {
		opacity: 1;
	}
}