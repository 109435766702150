.c-register {
  &__h1 {
    margin-bottom: 18px;
    font-size: 16px;
  }

  &__h2 {
    margin-bottom: 32px !important;
    line-height: 34px;
  }

  @include mq('to', 768) {
    &__h1 {
      margin-bottom: 27px;
      font-size: 12px;
      line-height: 1.2;

      span {
        display: block;
        margin-top: 3px;
      }
    }

    &__h2 {
      margin-bottom: 25px !important;
      line-height: 24px;
    }
  }

  &.container {
    min-height: initial;
    margin-top: 230px;
  }
}
