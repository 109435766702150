.c-homepage {
    &--main-content {
        text-align: left;

        img {
            width: 100%;
        }

        p,
        ol {
            line-height: 40px;
            margin-bottom: 40px;

            @include mq('to', 768) {
                line-height: 28px;
                margin-bottom: 25px;
            }
        }
    }
}
