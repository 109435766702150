.c-field-checkbox {
    display: flex;
    align-items: center;
    margin-top: 18px;

    @include mq("to", 768) {
        margin-top: 23px;
    }

    &__input {
        display: none;

        + label {
            font-size: 16px;
            font-family: $font-secondary;
            display: flex;
            align-items: center;

            &::before {
                content: "\e904";
                font-family: "icomoon" !important;
                color: #fffff3;
                background-color: #fffff3;
                font-size: 17px;
                padding: 0 4px;
                transform: translateY(2px);
                margin-right: 15px;
                transition: color 0.2s ease-in-out;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &[checked],
        &:checked {
            + label {
                &::before {
                    color: $black;
                }
            }
        }

        &[value=false] {
            + label {
                &::before {
                    color: #fffff3;
                }
            }
        }
    }
}
