.c-articles {
    &__header {
        margin-bottom: 70px;

        h1{
            margin-bottom: 10px;
        }

        h2 {
            margin-bottom: 10px;
        }

        @include mq('to', 768) {
            margin-bottom: 30px;
            h2 {
                font-size: 18px;
                margin-bottom: 5px !important;
            }
            
            h1 {
                &.font-uppercase {
                    line-height: 24px !important;
                    margin-bottom: 7px !important;
                }
            }
        }
    }
}