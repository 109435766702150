#CybotCookiebotDialog h2,
#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonDescription,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyEmptyCategoryMessage,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoTitle,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent {
    font-family: $font-secondary !important;
}

#CybotCookiebotDialogBodyContentText p,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
#CybotCookiebotDialogDetailBodyContentTextAbout,
#CybotCookiebotDialogDetailFooter,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoDescription {
    font-family: $font-primary !important;
}

#CybotCookiebotDialog {
    max-height: calc(100% - 120px) !important;

    @include mq("from", 601) {
        max-width: 600px !important;
        max-height: 60vh !important;
    }

    &.CybotEdge {
        .CybotCookiebotDialogBodyBottomWrapper {
            @include mq("from", 1280) {
                margin-top: 0 !important;
            }
        }
        
        #CybotCookiebotDialogBodyEdgeMoreDetails {
            justify-content: center !important;
            margin: 1em 0 0 !important;

            a {
                &::after {
                    margin-left: 0 !important;
                    color: inherit !important;
                }
            }
        }

        #CybotCookiebotDialogFooter,
        #CybotCookiebotDialogBodyButtons {
            @include mq("from", 1280) {
                height: 100%;
            }
        }
    }
}

#CybotCookiebotDialogHeader {
    display: none !important;
}

#CybotCookiebotDialogFooter {
    #CybotCookiebotDialogBodyButtonsWrapper {
        justify-content: center !important;
        align-items: center !important;
        gap: 10px;

        @include mq("from", 601) {
            flex-direction: row !important;
        }
    }

    .CybotCookiebotDialogBodyButton {
        height: 50px;
        margin: 0 !important;
        line-height: 0 !important;
    }

    #CybotCookiebotDialogBodyLevelButtonCustomize {
        display: none;
    }
}

#CybotCookiebotDialogPoweredByText {
    display: none !important;
}
