.c-forgot-password {
    h1 {
        font-size: 16px;
        line-height: 1 !important;

        @include mq('to', 768) {
            font-size: 12px !important;
        }
    }

    h4 {
        font-size: 24px;
        margin-bottom: 70px;

        @include mq('to', 768) {
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
}
