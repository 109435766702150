.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 130px auto 0;
  min-height: calc(100vh - 475px);

  @include mq('to', 768) {
    padding: 0 20px;
    min-height: 100%;

    &__basic-page {
      max-width: 100%;
    }

    &__wide {
      &--articles {
        padding: 0 7px;

        @include mq('to', 768) {
          margin-top: 120px;
        }
      }
    }
  }

  &__small-margin {
    margin: 35px auto 0;

    @include mq('to', 768) {
      margin: 65px auto 0;
    }
  }

  &__small-padding {
    padding: 0 15px;

    @include mq('to', 768) {
      padding: 0 20px;
    }
  }

  &__basic-page {
    max-width: 660px;

    &--main-content {
      text-align: left;
    }

    p,
    ul,
    h1 {
      @include mq('to', 768) {
        font-size: 18px;
        line-height: 32px;
      }
    }

    img {
      /*width: 558px;*/
      width: 100%;
      max-width: 100%;
      height: auto;
      transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;

      &.animated {
        opacity: 0;
        &.loaded {
          opacity: 1;
        }
      }

      /*
            @include mq('to', 768) {
                width: 100%;
            }
            */
    }
  }

  &__wide {
    max-width: 976px;

    &--articles {
      > p {
        line-height: 38px;
      }

      @include mq('to', 768) {
        h1 {
          font-size: 36px;
          letter-spacing: 0.02em;
          margin-bottom: 21px !important;
        }

        p {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 38px !important;
        }
      }
    }
  }

  //single page audiobook container
  &__big {
    max-width: 1440px;
    padding: 0;
    margin: 0 auto;
    @include mq('to', 768) {
      margin: 50px auto 0;
    }

    &--withPadding {
      padding: 0 12px;

      @include mq('to', 768) {
        padding: 0 8px;
      }
    }
  }

  &__newsletter {
    max-width: 600px;

    h1 {
      line-height: 1;
    }

    h4 {
      line-height: 19px;
      margin-bottom: 18px;
    }

    &__quote {
      line-height: 19px !important;
    }

    &__success {
      font-size: 24px;
    }

    @include mq('to', 768) {
      h1 {
        font-size: 12px;
      }

      &__success {
        font-size: 18px;
      }
    }
  }

  &--app-sign-up {
    margin: 0 auto 50px;
    min-height: calc(100vh - 50px);

    .thank-you-app-sign-up {
      margin-top: 160px;

      @include mq('from', 768) {
        margin-top: 240px;
      }
    }
  }
}

.full-width {
  width: 100%;
}

.tablet-none {
  @include mq('to', 768) {
    display: none;
  }
}

.margin-reset {
  margin: 0;
}

/*
    Generating margin-bottom
    Class example .mb-50
*/

@for $j from 00 to 105 {
  .mb-#{$j} {
    margin-bottom: 1px * $j !important;
  }
}

/*
    Generating margin-top
    Class example .mt-50
*/

@for $j from 0 to 150 {
  .mt-#{$j} {
    margin-top: 1px * $j !important;
  }
}

/*
    Generating margin-left
    Class example .ml-50
*/

@for $j from 00 to 100 {
  .ml-#{$j} {
    margin-left: 1px * $j !important;
  }
}

/*
    Generating margin-right
    Class example .mr-50
*/

@for $j from 00 to 100 {
  .mr-#{$j} {
    margin-right: 1px * $j !important;
  }
}

/*
    Generating padding-bottom
    Class example .pb-50
*/

@for $j from 00 to 100 {
  .pb-#{$j} {
    padding-bottom: 1px * $j;
  }
}

/*
    Generating padding-top
    Class example .pt-50
*/

@for $j from 00 to 100 {
  .pt-#{$j} {
    padding-top: 1px * $j;
  }
}

/*
    Generating padding-left
    Class example .pl-50
*/

@for $j from 00 to 100 {
  .pl-#{$j} {
    padding-left: 1px * $j;
  }
}

.gap-20 {
  gap: 20px;
}
