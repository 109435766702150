.c-audio-book-single-carousel-container {
    > .c-audio-book-single-carousel-heading h2,
    > h2{
        text-align: center;
        font-size: 36px;
        margin: 70px 5px 18px;

        @include mq("to", 768){
            text-align: left;
            font-size: 24px;
            margin: 80px 5px 13px;
        }
    }

    @include mq("to", 768) {
        order: 3
    }
}

.c-audio-book-single-carousel-heading {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 30px;
    margin: 63px 5px 18px;

    @include mq("to", 768){
        margin: 30px 0 18px;
    }

    h2 {
        flex: 1;
        text-align: left !important;
        margin: 0 !important;

        @include mq("to", 768){
            font-size: 22px !important;
        }
    }

    a {
        font-family: $font-secondary;
        font-size: 22px;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;

        @include mq("to", 768){
            font-size: 18px;
        }
    }
}

.c-audio-book-single-carousel {
    position: relative;
    max-height: 520px; // fix clone issues
    width: calc(100% - 12px);
    margin: 0 auto;
    overflow: hidden;
    padding: 6px 0 14px;

    &--show-shadow {
        width: calc(100% - 6px);
        margin-right: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 25%;
            height: calc(100% - 30px);
            background: linear-gradient(-90deg, #F4EEDC 0.52%, rgba(244, 238, 220, 0.03) 99.38%);
            pointer-events: none;
            z-index: 1;

            @include mq("to", 768) {
                background: linear-gradient(-90deg, #f4eedccd 0.52%, rgba(244, 238, 220, 0.03) 99.38%);
            }

            @include mq("from", 1440) {
                height: calc(100% - 13px);
            }
        }
    }

    .slick-list {
        overflow: visible;
        @include mq("to", 768) {
          padding-left: 9% !important;
        }
    }

    .slick-slide {
        margin-bottom: 15px; // fix clone issues
    }

    .slick-arrow {
        display: block !important;
    }

    .slick-arrow.slick-next {
        right: 25px;
        transform: scale(1.6) rotate(-90deg) translateX(50%); // icomoon
        z-index: 2;

        @include mq("to", 768) {
          display: none !important;
        }
    }

    .slick-arrow.slick-prev {
        display: none !important;
    }

    .slick-arrow.slick-next::before {
        content: "\e915" !important;
        font-family: 'icomoon' !important;
        speak: never !important;
        font-style: normal !important;
        font-weight: normal !important;
            font-variant: normal !important;
        text-transform: none !important;
        line-height: 1 !important;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
}