.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.2) !important;
    z-index: 77 !important;

    .popup-content {
        width: 500px;
        text-align: center;
        background-color: $light-yellow;

        @include mq('to', 768) {
            background-color: $yellow;
        }

        .icon-close-small {
            color: #000000;
            position: absolute;
            top: 12px;
            right: 12px;
            font-size: 12px;
        }
    
        @include mq('to', 768) {
            position: fixed;
            left: 0;
            top: 80px;
            width: 100%;
            height: calc(100% - 150px);
            overflow: scroll;
        }

        .c-popup {
            background-color: $light-yellow;
            padding: 30px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            position: relative;
            height: 400px;
            width: 530px;
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 100;
        
            @include mq('to', 768) {
                height: 100%;
                width: 100%;
                z-index: 74;
                padding: 25px;
                box-shadow: none;
                background-color: $yellow;
            }

            &--delete-account {
                padding: 95px 110px 50px;
                width: 700px;
                height: 500px;
                margin-left: -100px;

                @include mq('to', 768) {
                    height: 100%;
                    width: 100%;
                    padding: 25px;
                    margin-left: 0;
                }
            }

            &__quote {
                margin-top: 120px;

                @include mq('to', 768) {
                    margin-top: 40px;

                    span {
                        display: block;
                    }
                }
            }
        }

        .c-popup__action {
            display: flex;
            justify-content: space-between;
            position: sticky;
            top: 100vh;
            margin-top: 15px;

            @include mq('to', 768) {
                position: static;
            }

            &--delete-account {
                position: static;

                @include mq('to', 768) {
                    flex-direction: column;

                    button + button {
                        margin-left: 0 !important;
                        margin-top: 19px;
                    }
                }
            }

            button + button {
                margin-left: 15px;
            }
        }
    }    
}



