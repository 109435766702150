.Page403 {
    max-width: 550px;
    margin: 0 auto;

    > img {
        width: 100%;
        height: auto;
        display: block;
        margin-top: 100px;

        @media screen and (min-width: 768px) {
            margin-top: 250px;
            min-width: 520px;
        }
    }

    &__Actions {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}
