.c-author {
  margin-top: 90px;
  &__image {
    width: 313px !important;
  }

  &__bio {
    font-size: 24px;
    margin-top: 24px;
  }

  @include mq('to', 768) {
    margin-top: 40px;
  }
}
