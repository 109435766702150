.c-form {
    width: 320px;
    margin: 0 auto;

    @include mq("to", 768) {
        position: relative; // icon close modal
        width: 100%;
        max-width: 320px;
    }

    &__btn-container {
        height: 46px;
    }

    &__choose-password-heading {
        text-align: center !important;
        margin-bottom: 25px;

        @include mq("from", 768) {
            margin-bottom: 70px;
        }
    }

    h1, h2 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.15em;
        margin-bottom: 50px;

        @include mq("to", 768) {
            text-align: left;
            font-size: 12px !important;
            margin-bottom: 25px;
        }
    } 
    
    h1 {
        line-height: 1 !important;
    }
}

.icon-eye {
    position: absolute;
    color: $light-gray;

    &:hover {
        color: $light-gray;
    }

    &::after {
        content: "";
        position: absolute;
        top: 7px;
        left: 0;
        transform: rotateZ(45deg);
        height: 1px;
        width: 0px;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out;
    }

    &.show {
        &::after {
            width: 22px;
            background-color: $light-gray;
        }
    }
}

.pre-form {
    &--app-sign-up {
        margin-top: 160px;

        @include mq("from", 768) {
            margin-top: 240px;
        }
    }

    h2 {
        font-size: 24px;
        max-width: 530px;
        margin: 0 auto;

        @include mq("to", 768) {
            font-size: 18px;
        }
    }
}

.password-field {
    position: relative;

    &--eye {
        position: absolute;
        top: 14px;
        right: 12px;
    }

    &.labeled {
        .password-field--eye {
            top: 38px;
        }
    }
}

.country-flags {
    font-family: 'Gill Sans Nova';

    &--settings {
        div button {
            letter-spacing: 0 !important
        }
    }

    div {
        background: #fffff3;
        padding: 0;

        > button {
            height: 46px;
            border-radius: 0;
            border: 0;                   
            padding: 0 15px;
            color: $black;
            letter-spacing: 0.02em;

            &::after {
                width: 8px;
                height: 8px;
                border: 2px solid $black;
                border-top: 0;
                border-right: 0;
                transform: rotate(-45deg);
                margin-right: 6px;
                margin-top: -2px;
            }

            &[aria-expanded="true"]::after {
                width: 8px;
                height: 8px;
                border: 2px solid $black;
                border-bottom: 0;
                border-left: 0;
                margin-top: 11px;
            }

            span {     
                padding: 0;  
                color: $light-gray;  

                span:first-of-type {
                    font-size: 30px;
                }

                span + span {
                    padding-left: 15px;    
                    color: $black;                
                }  
            }
        }

        ul {
            background: #fffff3;
            border: none;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-top: 1px solid rgba(0, 0, 0, 0.3);  
            border-radius: 0;
            margin-top: 0;
            width: 320px;
            max-height: 268px;
            margin-left: 0;
            
            > div {
                background: #fffff3;
                padding: 0;
                z-index: 1;
                input {
                    padding: 0;
                    height: 40px;
                    width: calc(100% - 25px);
                    margin: 6px 10px 0 15px;
                    letter-spacing: 0.02em;

                    &::placeholder {
                        font-size: 14px;
                    }
                    &:focus {
                        border-bottom: 1px solid $black;
                    }
                }
            }

            li {
                font-size: 16px;
                line-height: 1.3;
                padding: 6px 15px;
                margin: 0;
                font-style: normal;
                letter-spacing: 0.02em;

                &:first-of-type {
                    margin-top: 6px;
                }

                span {
                    span:first-of-type {
                        font-size: 30px;
                    }

                    span + span {
                        padding-left: 15px;
                    }                    
                }

                &:hover {
                    background: $yellow;
                }
            }
        }
    }
}
