.c-navigation {
    background-color: $yellow;
    z-index: $zHeader;

    &__mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 17.5px 20px;
        background-color: $yellow;
        /* comment - stacking context
        &.shadow {
            @include mq('to', 768) {
                &::before {
                    content: '';
                    width: 100%;
                    height: 90px;
                    position: absolute;
                    top: -90px;
                    left: 0;
                    background: linear-gradient(180deg, #f4eedc 21.11%, rgba(244, 238, 220, 0) 67.78%);
                    transform: rotate(-180deg);
                    pointer-events: none;
                }
            }
        }
        */

        @include mq('from', 768) {
            display: none !important;
        }

        &--active {
            li {
                opacity: 1 !important;
            }
        }

        li {
            opacity: .25;
        }
    }

    &__header {
        padding: 0 10px;
        &--item {
            list-style-type: none;
            color: $black;
            font-size: 25px;

            &--active {
                opacity: 1 !important;
            }

            i {
                position: relative;
            }

            &:hover {
                cursor: pointer;
            }

            span {
                position: absolute;
                top: 9px;
                left: 4px;
                width: 12px;
                text-align: center;
            }
        }
    }

    &__search-navigation,
    &__library-navigation,
    &__burger-navigation,
    &__user-navigation,
    &__shop-navigation {
        position: fixed;
        top: 0;
        width: 400px;
        bottom: 0;
        padding: 34px 40px 34px 54px;
        background-color: $yellow;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease-in-out;
        opacity: 0;
        overflow: auto;

        &.menu-open {
            transform: translateY(0);
            opacity: 1;
        }

        @include mq('to', 768) {
            width: 100%;
            bottom: 70px; // comment - mobile toolbar height 70
            right: 0;
            overflow-y: auto;
            box-shadow: none;
            transform: translateY(-102%);
        }
    }

    &__search-navigation,
    &__library-navigation,
    &__user-navigation,
    &__shop-navigation {
        right: 0;
        transform: translateX(102%);

        &__burger-menu {
            height: 24px;
            margin-top: 6px;

            &:hover {
                cursor: pointer;
            }

            &--line {
                height: 1px;
                width: 24px;
                background-color: $black;
                margin-bottom: 9px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__burger-navigation {
        left: 0;
        transform: translateX(-102%);
        padding: 38px 22px 34px 41px;

        @include mq('to',768) {
             padding: 30px 22px 34px 24px;
        }

        h3 {
            margin-left: 7px;
        }

        &__burger-menu {
            margin-left: 7px;
            display: inline-block;
        }

        &--burger-menu {
            height: 24px;
            margin-top: 6px;

            &:hover {
                cursor: pointer;
            }

            &--line {
                height: 1px;
                width: 24px;
                background-color: $black;
                margin-bottom: 9px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        &__footer {
            margin-left: 7px;

            li {
                list-style-type: none;
                margin-bottom: 18px;
            }

            // comment - not exist in html
            &--item {
                text-transform: uppercase;
                color: $black;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
                position: relative;

                i {
                    position: relative;
                }

                &:hover {
                    cursor: pointer;
                }

                span {
                    position: absolute;
                    top: 9px;
                    left: 4px;
                    width: 12px;
                    text-align: center;
                }
            }
        }

        &--footer--item {
            display: block;
            width: max-content;
            text-transform: uppercase;
            color: $black;
            transition: all 0.3s ease-in-out;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__search-navigation {
        padding: 30px 8px 34px;
        display: flex;
        flex-direction: column;

        @include mq('to', 768) {
             padding: 30px 8px 24px;
        }

        .c-navigation__header {
            padding: 0 40px 34px 30px;

            @include mq('to', 768) {
                padding: 0 20px 34px 21px;
            
            }
        }

        &__input {
            position: relative;
            padding: 0 18px;

            input {
                margin-bottom: 23px;
                margin-left: 7px;
                width: calc(100% - 14px);
                font-size: 22px;
                font-style: italic;
                padding: 8px 30px 8px 0;
                height: auto;
                letter-spacing: 0.02em;

                &::placeholder {
                    font-style: normal;
                    letter-spacing: .15em;
                }

                &:focus {
                    border-bottom: 1px solid $black;
                }
            }

            i {
                position: absolute;
                top: 20px;
                right: 35px;
                text-shadow: 0 0 1px $black;
            }
        }

        h3 {
            margin: auto 0 0 7px;

            @include mq('from',768) {
                margin-top: 0px;
            }

            + div {
                margin-top: 12px;
                margin-bottom: 0;
            }
        }
    }

    &__user-navigation {
        padding: 30px 45px 34px 34px;

        @include mq('to',768) {
            padding: 28px 30px 34px;

            .c-navigation__header {
                padding: 0
            }
        }
    }

    &__library-navigation {
        padding: 0 20px 34px;

        .c-navigation__header {
            padding: 30px 28px 0 20px; 

            @include mq('to', 768) {
                padding: 28px 10px 0;
            }
        }
    }

    &__shop-navigation {
        padding: 0 20px 25px;

        .c-navigation__header {
            padding: 30px 28px 22px 20px; 

            @include mq('to', 768) {
                padding: 28px 10px 22px;
            }
        }
    }

    &__search-audiobooks {
        position: relative;

        &--shadow::before {
            content: '';
            position: absolute;
            bottom: 0px;
            left: -3px;
            width: calc(100% + 6px);
            height: 22%;
            background: linear-gradient(0deg, $yellow, rgba(244, 238, 220, 0.3));
            z-index: 1;
            pointer-events: none;
        }

        &__show-filters {
            position: absolute;
            bottom: -45px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.c-navigation-mobile-shadow {
    width: 100%;
    height: 90px;
    position: fixed;
    bottom: 69px; // comment - mobile toolbar height 70
    left: 0;
    background: linear-gradient(180deg, #f4eedc 21.11%, rgba(244, 238, 220, 0) 67.78%);
    transform: rotate(-180deg);
    z-index: $zIndexMobileShadow;
    pointer-events: none;

    @include mq('from', 768) {
        display: none;
    }
}
