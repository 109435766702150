.c-confirm-user {
    @include mq("to", 768) {
        margin-top: -40px;
    }

    &__success {
        margin-top: -15px;

        @include mq("to", 768) {
            padding: 0 7px;
            margin-top: -48px;
        }
        
        > p:first-of-type {
            margin-bottom: 40px;
            line-height: 40px;

            @include mq("to", 768) {
                line-height: 24px !important;
                margin-bottom: 25px;
            }
        }

        > p:nth-of-type(2) {
            line-height: 40px;
            max-width: 600px;

            @include mq("to", 768) {
                line-height: 24px !important;
                margin-bottom: 20px;
                max-width: 290px;
            }
        }

        > p:nth-of-type(3) {
            line-height: 40px;

            @include mq("to", 768) {
                line-height: 24px !important;
                max-width: 340px;
            }
        }

        > div {
            &:first-of-type {
                margin-top: -68px;
                margin-bottom: -35px;

                @include mq("to", 768) {
                    display: none;
                }

                img {
                    width: 381px !important;
                    height: 381px !important;
                }
            }
        }

        ol {
            @include mq("to", 768) {
                margin-left: 23px;
            }

            // li {
            //     max-width: 380px;
            // }
        }
    }

    &__get-book-problem {
        text-align: center;
        margin-bottom: 50px;

        img {
            width: 328px !important;
            height: auto;
        }
    }

    &__spiracle-member {
        line-height: 1;
        text-transform: uppercase;
        padding: 14px 16px 10px;
        margin-top: 45px;
        border-radius: 100px;

        @include mq("to", 768) {
            margin-top: 38px;
            padding: 14px 8px 10px;
        }
    }

    &__error {
        p:first-of-type {
            margin-bottom: 40px;
            font-size: 28px;

            @include mq("to", 768) {
                margin-bottom: 22px;
                font-size: 18px;
                line-height: 24px !important;
            }
        }

        p:last-of-type {
            margin-bottom: 40px;
            font-size: 28px;

            @include mq("to", 768) {
                margin-bottom: 15px;
                font-size: 18px;
                line-height: 24px !important;
            }
        }
    }

    &__underline {
        &:hover {
            &::after {
                display: none !important;
            }
        }
    }
}
