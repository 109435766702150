.c-authentication {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 700px;
    background-color: $light-yellow;
    display: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    z-index: $zModal;

    &__register {
        padding: 30px 100px;
        min-height: 605px;
        height: auto;
        // display: flex;
        // align-items: center;

        @include mq("to", 768) {
            padding: 30px 0;

            .c-authentication__wrapper--close-modal {
                top: -130px;
                right: 0;
            }
        }

        h4 {
            font-size: 24px;

            @include mq("to", 768) {
                font-size: 18px;
            }
        }
    }

    &__login {
        @include mq("to", 768) {
            .c-authentication__wrapper--close-modal {
                top: -58px;
                right: 0;
            }
        }
    }

    &__forgot-password {
        @include mq("to", 768) {
            .c-authentication__wrapper--close-modal {
                top: -98px;
                right: 0;
            }
        }
    }

    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 100px;
        overflow: auto;

        .c-authentication__register & {
            padding: 0;
            overflow: hidden;
            // min-height: 535px;

            @media screen and (max-height: 750px) {
                overflow: auto !important;
                padding-top: 82px;
                
                .show-banner & {
                    padding-top: 200px;
                }
            }
        }

        &--close-modal {
            color: $black;
            position: absolute;
            top: 12px;
            right: 12px;
        }

        &--desktop {
            padding: 0 155px;
        }
    }

    &__form-wrapper {
        width: 100%;

        h3 {
            line-height: 16px;
        }
    }

    &.visible {
        display: block;
    }

    &__sign-up-h3 {
        margin-bottom: 20px;
    }

    &__sign-up-h4 {
        margin-bottom: 20px !important;
        line-height: 24px;

        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -o-font-smoothing: antialiased;

        @include mq("from", 768) {
            line-height: 34px;
            margin-top: 28px !important;
        }
    }

    &__sign-up-span {
        @include mq("to", 768) {
           display: block;
        }
    }

    @include mq("to", 768) {
        position: fixed;
        top: 0;
        left: 0;
        height: calc(100% - 70px); // comment - mobile toolbar height 70
        width: 100%;
        transform: initial;
        background-color: $yellow;
        box-shadow: none;
        z-index: $zModalMobile;

        &__form-wrapper {
            width: 320px;
            margin: 0 auto;
        }

        &__wrapper {
            padding: 0;
            position: relative;
        }
    }

    @media screen and (max-height: 750px) {
        &__form-wrapper {
            padding-top: 90px;
        }
    }
}
