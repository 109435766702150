@import '../../scss/variables';

// .LoaderTop {
//     pointer-events: none;

//     .Loader {
//         position: fixed;
//         top: 46px;
//         left: 165px;
//         height: 28px;
//         z-index: 99;

//         @media screen and (max-width: 768px) {
//             top: 40px;
//             left: 48px;
//         }

//         path {
//             stroke-dashoffset: 0;
//             animation: none;
//         }
//     }
// }

.LoaderContainerBig {
    position: relative;
    height: 100vh;
}

.LoaderContainer {
    position: relative;
    height: calc(100vh - 60px);

    @media screen and (min-width: 768px) {
        height: calc(100vh - 416px);
    }
}

.LoaderContainerSmall {
    position: relative;
    height: 51px;
}

.Loader {
    height: 150px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 1s;

    path {
        stroke-width: .1;

        .LoaderContainerSmall & {
            stroke-width: .2;
        }
    }

    .LoaderContainerSmall & {
        height: 50px;
    }
}