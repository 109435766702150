.font-italic {
    font-style: italic;
}

.font-bold {
    font-weight: 700;
}

.font-uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.letter-spacing-15 {
    letter-spacing: 0.15em;
}

.letter-spacing-02 {
    letter-spacing: 0.02em;
}

/*
    Font weight generator
    ex class .font-400
*/

@for $j from 1 to 9 {
    .font-#{$j}00 {
        font-weight: 100 * $j !important;
    }
}

/*
    Letter spacing generator
    ex class .lh-15
*/

@for $j from 1 to 30 {
    .ls-#{$j} {
        letter-spacing: 0.01em * $j !important;
    }
}

/*
    Line height generator
    ex class .lh-10
*/

@for $j from 10 to 60 {
    .lh-#{$j} {
        line-height: 1px * $j !important;
    }
}

/*
    Font size generator
    ex class .font-16
*/

@for $j from 8 to 100 {
    .font-#{$j} {
        font-size: 1px * $j;
    }
}

/*
    opacity generator
    ex class .opacity-9
*/

@for $j from 0 to 10 {
    .opacity-#{$j} {
        opacity: 0.1 * $j;
    }
}

.font-black {
    color: $black;
}

.font-gray {
    color: $light-gray;
}

.font-brick-red {
    color: $brick-red;
}

.font-blue {
    color: $blue;
}

.font-brown {
    color: $brown;
}

.underline {
    text-decoration: underline;

    &::before {
        display: none;
    }
}
