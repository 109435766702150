.c-header {
    background-color: $yellow;

    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $yellow;
        padding: 26px 30px;
        z-index: $zHeader;
        transition: all 0.3s ease-in-out;

        .show-banner & {
            top: 62px;
        }

        .show-banner-payment-failed & {
            top: 61px;
        }

        .show-banner.show-banner-payment-failed & {
            top: 123px;
        }

        @include mq("from", 768) {
            top: 0;
            left: 0;
            width: 100%;
            padding: 22px 48px;

            .show-banner & {
                top: 63px;
            }

            .show-banner-payment-failed & {
                top: 62px;
            }

            .show-banner.show-banner-payment-failed & {
                top: 123px;
            }
        }

        @include mq("from", 960) {
            .show-banner-payment-failed & {
                top: 63px;
            }

            .show-banner.show-banner-payment-failed & {
                top: 123px;
            }
        }

        &--app-sign-up {
            @include mq("to", 768) {
                top: 0;
                padding: 26px 10px;
            }
        }

        &.show {
            @include mq("to", 768) {
                transform: translateY(0);
            }
        }

        &.hide {
            @include mq("to", 768) {
                transform: translateY(-100%);
                top: 0 !important; // if Banner exist
            }
        }
    }

    &__logo-wrapper {
        width: 180px;

        &--shop {
            @include mq("from", 768) {
                display: none !important;
            }

            &:hover {
                cursor: pointer;
            }

            i {
                position: relative;

                span {
                    position: absolute;
                    top: 9px;
                    left: 4px;
                    width: 12px;
                    text-align: center;
                }
            }
        }

        @include mq("to", 768) {
            width: 100%;
        }
    }

    &__logo {
        font-size: 26px;
        color: $black;
        text-decoration: none;

        img {
            height: 28px;
            width: auto;
            margin-right: 5px;
        }
    }

    &__burger-menu {
        height: 24px;
        margin-top: 6px;

        &--line {
            height: 1px;
            width: 24px;
            background-color: $black;
            margin-bottom: 9px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        @include mq("to", 768) {
            display: none;
        }
        @include mq("from", 1260) {
            display: none;
        }
    }

    &__right {
        flex: 1;
        justify-content: space-between;

        @include mq("to", 768) {
            display: none !important;
        }

        &--list {
            list-style-type: none;
            width: 100%;
            gap: 40px;

            &--item {
                list-style-type: none;
                position: relative;

                i {
                    &:hover {
                        cursor: pointer;
                    }
                }

                span {
                    position: absolute;
                    top: 9px;
                    left: 4px;
                    width: 12px;
                    text-align: center;
                    pointer-events: none;
                }
            }

            &--desktop-only {
                @include mq("to", 1260) {
                    display: none !important;
                }
            }

            &--search,
            &--audiobooks {
                margin-left: auto;
            }
        }

        &--item {
            cursor: pointer;

            &:hover {
                & + .c-header__right--sub-list {
                    display: block;
                }
            }
        }

        &--sub-list {
            position: absolute;
            top: 20px;
            min-width: 302px;
            list-style: none;
            background: #fff;
            padding: 18px 24px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
            display: none;

            &:hover {
                display: block;
            }

            li {
                margin-bottom: 10px;
                font-family: 'Gill Sans Nova';
                font-size: 20px;
            }
        }
    }
}
