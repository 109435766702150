.c-footer {
    padding: 33px 40px;
    position: relative;
    min-height: 278px;

    @include mq('to', 768) {
        display: block !important;
        margin-bottom: 144px; // comment - mobile toolbar height 70
        padding: 33px 20px 0;

        &__bigMargin {
            margin-bottom: 210px;
        }
    }

    &__link-list {
        li {
            list-style-type: none;
            margin-bottom: 5px;

            &:first-child {
                margin-bottom: 8px;

                a {
                    &::before {
                        display: none;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            @include mq('to', 768) {
                margin-bottom: 5px;
                display: flex;
                justify-content: center;

                &:first-child {
                    margin-bottom: 13px;
                }
            }
        }
    }

    &__social-list {
        display: flex;
        align-items: flex-end;
        position: absolute;
        left: 50%;
        bottom: 27px;
        transform: translateX(-50%);

        @include mq('to', 920) {
            transform: translateX(-100%);
        }

        @include mq('to', 768) {
            position: initial;
            transform: none;
            flex-wrap: wrap;
            width: max-content;
            margin: 30px auto 75px;
        }

        li {
            list-style-type: none;
            margin-right: 15px;

            a {
                color: $black;
                text-decoration: none;
                i {
                    font-size: 25px;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__newsletter {
        max-width: 320px;

        h4 {
            margin-bottom: 37px;
        }

        @include mq("to", 768) {
            margin: 20px auto 0;

            h3 {
                font-size: 12px;
            }

            h4 {
                font-size: 18px;
                margin-bottom: 28px;
                line-height: 24px;
            }
        }

        p {
            font-size: 18px;

            @include mq("to", 768) {
                font-size: 26px;
            }
        }
    }
}
