.container {
    &--info-pages-landing-page {
        .c-info-page {
            &__header {
                border-bottom: none;
            }
        }

        .books-container {
            &__title {
                text-align: center;
                font-size: 18px;
                font-style: italic;
                font-family: $font-primary;
                margin: 0 5px 23px;

                @include mq('from', 768) {
                    font-size: 36px;
                    margin: 0 5px 25px;
                }
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                box-sizing: border-box;
                width: calc(100% + 12px);
                margin: -6px;
            }
        }

        .book-item {
            margin: 0;
            padding: 6px;
            box-sizing: border-box;
            flex-grow: 0;
            max-width: 50%;
            flex-basis: 50%;

            div {
                margin-bottom: 0;
            }

            &__link {
                display: block;

                &::before {
                    display: none;
                }
            }

            &__holder {
                background-color: #f9f7ed;
                color: #000000;
                box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
                border-radius: 2px;
                padding: 6px;
                width: 100%;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
                position: relative;

                @include mq('from', 768) {
                    padding: 6px 6px 1px;
                }
            }

            &__cover-image-holder {
                position: relative;
                text-align: initial;
                -webkit-transition: all 0.35s cubic-bezier(0.785,0.135,0.15,0.86) 0s;
                transition: all 0.35s cubic-bezier(0.785,0.135,0.15,0.86) 0s;
                opacity: 1;

                @include mq('from', 375) {
                    max-width: 100%;
                }

                img {
                    // position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__content-holder {
                position: relative;
                height: 145px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                padding: 7px 12px 4px;
                text-align: left;

                @include mq('from', 768) {
                    display: block;
                    height: 160px;
                    padding: 20px 12px 12px;
                }
            }

            &__title {
                font-size: 14px;
                line-height: 18px;
                -webkit-letter-spacing: 0.03em;
                -moz-letter-spacing: 0.03em;
                -ms-letter-spacing: 0.03em;
                letter-spacing: 0.03em;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 10px;

                @include mq('from', 768) {
                    font-size: 20px;
                    line-height: 26px;
                    -webkit-letter-spacing: 0.02em;
                    -moz-letter-spacing: 0.02em;
                    -ms-letter-spacing: 0.02em;
                    letter-spacing: 0.02em;
                    -webkit-flex: 35px 0 0;
                    -ms-flex: 35px 0 0;
                    flex: 35px 0 0;
                    text-align: left;
                    white-space: nowrap;
                    display: block;
                }
            }

            &__author {
                font-size: 14px;
                font-style: italic;
                line-height: 17px;
                -webkit-letter-spacing: 0.03em;
                -moz-letter-spacing: 0.03em;
                -ms-letter-spacing: 0.03em;
                letter-spacing: 0.03em;
                position: absolute;
                top: 51px;
                width: calc(100% - 24px);
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 0 3px 3px;
                margin: 6px 0 -3px -3px !important;

                @include mq('from', 768) {
                    font-size: 20px;
                    line-height: 24px;
                    -webkit-letter-spacing: 0.02em;
                    -moz-letter-spacing: 0.02em;
                    -ms-letter-spacing: 0.02em;
                    letter-spacing: 0.02em;
                    -webkit-flex: 35px 0 0;
                    -ms-flex: 35px 0 0;
                    flex: 35px 0 0;
                    margin: 25px 0 27px !important;
                    position: static;
                    width: auto;
                }
            }

            &__price {
                display: block;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                font-size: 20px;
                line-height: 24px;
                font-weight: 300;
                width: 100%;
                -webkit-transition: .3s ease-in-out;
                transition: .3s ease-in-out;

                @include mq('from', 768) {
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    font-size: 22px;
                    line-height: 24px;
                    -webkit-flex: 0 0 25px;
                    -ms-flex: 0 0 25px;
                    flex: 0 0 25px;
                }

                div + div {
                    margin: 10px 0 0 !important;

                    @include mq('from', 768) {
                        margin-left: 23px;
                        margin-top: 0;
                    }
                }

                i {
                    font-size: 14px;
                    margin-right: 8px;

                    @include mq('from', 768) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
