// bg-white
.flatpickr-input {
    background-color: #fffff3;
    color: $black;
    padding: 15px;
    border: none;
    
    &.invalid {
        background: rgba(255, 0, 0, 0.08);
        border: 1px solid rgba(255, 0, 0, 0.63);
        color: $error-red;
    }

    &:focus {
        background-color: #fffff3;
        color: $black;
    }

    &::placeholder {
        color: $light-gray;
        font-size: 16px;
        letter-spacing: normal;
    }
}

.flatpickr-calendar {
    font-family: 'Gill Sans Nova';
    width: 320px !important;
    border-radius: 0 !important;

    &.arrowTop {
        border-top: 1px solid rgba(0, 0, 0, .3);
        box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        margin-top: -2px;
    }

    &.arrowBottom {
        border-bottom: 1px solid rgba(0, 0, 0, .3);
        box-shadow: 0 -4px 4px rgba(0, 0, 0, .25);
        margin-top: -2px;
    }

    &::before, &::after {
        content: none !important;
    }

    .flatpickr-months {
        .flatpickr-prev-month,
        .flatpickr-next-month {
            color: $black;
            fill: $black;

            &:hover {
                color: $black;

                svg {
                    fill: $black;
                }
            }

            svg {
                display: block;
            }
        }

        .flatpickr-month {
            border-radius:  0;
            background: $yellow;
            color: $black;
            fill: $black;

            .flatpickr-current-month {
                padding: 5px 0 0 0;

                .flatpickr-monthDropdown-months {
                    background: transparent;
                    font-weight: 700;
                    font-size: 16px;
                    text-align: center;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: '';
                    width: 90px;
                }

                .numInputWrapper {
                    &:hover {
                        background: transparent;
                    }

                    input {
                        font-weight: 700;
                        font-size: 16px;
                        pointer-events: none;
                    }

                    span {
                        border: none;

                        &.arrowUp {
                            &::after {
                                border-bottom: 4px solid $black;
                            }
                        }

                        &.arrowDown {
                            &::after {
                                border-top: 4px solid $black;
                            }
                        }

                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }

    .flatpickr-innerContainer {
        border: none;

        .flatpickr-rContainer {
            width: 320px;

            .flatpickr-weekdays {
                background: transparent;
                border-bottom: 1px solid rgba(0, 0, 0, .3);

                .flatpickr-weekday {
                    background: transparent;
                    color: $black;
                    font-weight: 500;
                }
            }

            .flatpickr-days {
                width: 320px;
                border: none;

                .dayContainer {
                    width: 320px;
                    min-width: 320px;
                    max-width: 320px;

                    .flatpickr-day {
                        border-radius: 0;
                        border: none;
                        width: 42px;
                        min-width: 42px;
                        max-width: 42px;
                        height: 42px;
                        line-height: 42px;

                        &.today {
                            border: none;
                            background: $yellow;
                            color: $brick-red;
                            font-weight: 500;
                        }

                        &.selected {
                            border: none;
                            background: $brick-red;
                            color: $white;
                        }

                        &:not(.selected):not(.flatpickr-disabled) {
                            &:hover {
                                border: none;
                                background: $yellow;
                            }
                        }
                    }
                }
            }
        }
    }
}