.Intro {
    background-color: #F9F5E9;
    padding: 80px 0;
    margin-top: -50px;
    
    @media screen and (max-width: 1200px) {
        margin-top: -70px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 0;
        padding: 30px 0;
    }
    
    >div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        @media screen and (max-width: 1200px) {
            grid-template-columns: 1fr;
        }
        
        h1 {
            line-height: normal;
        }

        >div {

            &:first-child {
                padding-right: 130px;

                @media screen and (max-width: 1200px) {
                    padding-right: 0;
                    padding-bottom: 30px;
                }
            }
        }
    }

    h2 {
        font-size: 36px;

        @media screen and (min-width: 1200px) {
            font-size: 60px;
        }
    }

    img {
        max-width: 100%;
        height: auto;

        @media screen and (min-width: 1200px) {
            max-width: 100%;
        }
    }

}