.spiral-svg {
    stroke-dasharray: 155;
    stroke-dashoffset: 155;
    animation: dash 2s ease-in-out infinite;
}

@keyframes dash {
    50% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 155;
    }
}