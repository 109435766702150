.show-filters {   
    display: flex;
    justify-content: center;     
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;    
    cursor: pointer;
    transition: ease-in-out transform .5s, opacity .5s, box-shadow .3s, margin-right .3s;
    border: 1px solid black;   
    margin: 3px;   

    @media(hover:hover) {
        &:hover {
            border-color: $brick-red;
            color: $brick-red;
        }    
    }
    
    &:after {
        font-family: 'icomoon';
        content: "\e917";   
        font-size: 15px;        
    }    

    &.active {
        &:after {    
            font-family: inherit;        
            content: "—";   
            font-size: 24px;                    
        }
    }
}    