// Checkbox switch
.c-switch {

  @include mq('to', 767) {

    &--mobile-visible {
      display: block;
    }

    &--mobile-invisible {
      display: none;
    }
  }

  &__input {
    display: none;

    &:disabled {
      + label {
        opacity: .5;
        pointer-events: none;
      }
    }

    &:checked {
      + label::after {
        background-color: $white;
        transform: translateX(120%);
      }
      + label {
        background-color: $black;
      }
    }
  }

  &__label {
    display: block;
    border-radius: 15px;
    width: 62px;
    height: 30px;
    background-color: $white;
    transition: background-color 0.1s ease;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      background-color: $black;
      transition: all .1s ease;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
