//Spiracle colors
$yellow: #f4eedc;
$brick-red: #721b00;
$light-gray: #bdbab1;
//$error-red: #9f2600;
$error-red: rgba(255, 0, 0, .8);
$light-yellow: #f6f2e6;
$lighter-yellow: #f9f7ed;
$dark-yellow: #F1E5A3;
$brown: #5D5742;
$beige: #F9F5E9;
$light-pink: #EBDEDB;
$blue: #47507C;
$black: #000000;
$gray: #4e4c4c;
$white: #ffffff;
$mine-shaft: rgba(0, 0, 0, 0.75);

/* icoomoon variables */
$font-primary: "JJannon";
$font-secondary: "Gill Sans Nova";

/* icoomoon variables */
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts/icomoon" !default;

// z-index
$zModal: 100;
$zModalMobile: 74;
$zHeader: 80;
$zIndexFixBtn: 70;
$zIndexMobileShadow: 60;
