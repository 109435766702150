@import "../../../../scss/variables";

.Testimonials {
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    
    @media screen and (min-width: 990px) {
        padding: 80px 0;
    }

    &__SubTitle {
        letter-spacing: 6px;
    }

    &__Content {
        flex-direction: column;
        gap: 50px;
        
        @media screen and (min-width: 990px) {
            flex-direction: row;
            gap: 91px;
        }

        &__Item {
            flex-direction: column;
            position: relative;
            padding-left: 86px;
            max-width: 417px;

            &::before {
                content: '“';
                position: absolute;
                top: -30px;
                left: -2px;
                font-size: 200px;
                line-height: 244px;
                color: rgba(0, 0, 0, 0.05);
            }
        }
    }
}