a {
    color: $black;
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease;
    display: inline-block;    

    &.animated-underline {
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            width: 0;
            background-color: $black;
            transition: width 0.3s ease;
        }

        &:hover {
            color: $black;
            &::before {
                width: 100%;
            }
        }
    }

    &.c-btn {
        &::before {
            display: none;
        }
    }
}

.underlined {
    &::before {
        content: '';
        position: absolute;
        bottom: 3px;
        left: 0;
        background-color: $black;
        width: 100%;
        height: 1px;
    }

    &:hover {
        color: $brick-red;

        &::before {
            background-color: $brick-red;
        }
    }       

    &.font-secondary {
        &::before {
            bottom: 1px;
        }
    }
}

.link-underlined {
    a {
        @extend .underlined;
    }
}

i {
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: inline-block;

    &:hover {
        cursor: pointer;
        color: $brick-red;

        @include mq("to", 768) {
            color: $black;
        }
    }

    &:active {
        color: $black;
    }

    &.icon-close,
    &.icon-close-small {
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: rotateZ(180deg);
            color: $black;
        }
    }

    &.no-animation {
        transition: none;

        &:hover {
            transform: none;
            color: inherit;
        }
    }

    &.no-hover {
        &:hover {
            cursor: default;
            color: inherit;
        }
    }

    &.disabled {
        color: rgba($black, .3);
        cursor: default;
        pointer-events: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1.5em 0;
    color: $black;
    line-height: 1.2em;
    font-weight: normal;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    line-height: 40px;
    letter-spacing: 0.15em;

    @include mq('to', 768) {
        line-height: 28px;
    }
}

p {
    margin-bottom: 1em;
    color: $black;
}

.container {
    ul {
        margin-left: 45px;

        li {
            list-style-type: none;
            position: relative;
            font-size: 24px;
            line-height: 35px;
            font-style: italic;

            // comment - is this necessary (homepage design is changed)? yes (info pages)

            &::before {
                content: '';
                height: 5px;
                width: 5px;
                border-radius: 50%;
                background-color: $black;
                position: absolute;
                top: 12px;
                left: -23px;
            }
        }

        @include mq('to', 768) {
            margin-left: 15px;

            li {
                font-size: 18px;
                line-height: 24px;

                &::before {
                    top: 6px;
                    left: -16px;
                    height: 3px;
                    width: 3px;
                }
            }
        }
    }

    ol {
        margin-left: 45px;

        li {
            position: relative;
            font-size: 28px;
            line-height: 40px;
            font-style: italic;
        }

        @include mq('to', 768) {
            margin-left: 15px;

            li {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}
