//GILL SANS NOVA
@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ UltraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ UltraLight\ It.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Light\ Italic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Book.ttf');
    font-weight: 400;
    font-style: normal; 
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Book\ Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Medium\ Italic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ SemiBold\ Italic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Bold\ Italic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: $font-secondary;
    src: url('../fonts/gill-sans-nova/Monotype\ \ -\ Gill\ Sans\ Nova\ Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

//JJANON
@font-face {
    font-family: $font-primary;
    src: url('../fonts/jjannon/JJannon-Regular.ttf');
    font-weight: 400;
    font-style: normal;    
}

@font-face {
    font-family: $font-primary;
    src: url('../fonts/jjannon/JJannon-Italic.ttf');
    font-weight: 400;
    font-style: italic;    
}

html, body {
    font-family: $font-primary;
}

.font-primary {
    font-family: $font-primary;
}

.font-secondary {
    font-family: $font-secondary;
}