* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

html,
body,
button,
input,
select,
textarea {
    font-size: 16px;
    font-family: $font-secondary;
    line-height: 1.4;
    background-color: $yellow;
}

html {
    body {
        min-height: 100vh;
        overflow-x: hidden;

        &.noScroll {
            overflow: hidden !important;      
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0; 
        }

        &.edition {
            background-color: $light-pink;
        }
    }
}

main {
    //padding-top: 30px; // comment 110px???
    padding-top: 110px;
    min-height: calc(100vh - 279px); // newsletter margin-bottom
    
    &:has(.styled-murmurations) {
        overflow-x: hidden; // Homepage2 - StyledMurmurations
    }

    .show-banner &,
    .show-banner-payment-failed & {
        padding-top: 173px;
    }

    .show-banner.show-banner-payment-failed & {
        padding-top: 233px;
    }

    @include mq("to", 768) {
        padding-top: 30px; 
        min-height: initial;

        .show-banner & {
            padding-top: 92px;
        }

        .show-banner-payment-failed & {
            padding-top: 91px;
        }

        .show-banner.show-banner-payment-failed & {
            padding-top: 154px;
        }
    }
}

input {
    -webkit-appearance: none;
    -webkit-border-radius:0px;
}

// lightblue color on mobile 

input,
textarea,
button,
select,
a, 
div,
.rhap_progress-container {
     @include mq("to", 768) {
        -webkit-tap-highlight-color: transparent !important;
        outline: none !important;
     }
}
