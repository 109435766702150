@import "../../../../scss/variables";

.Choose {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    @media screen and (max-width: 767px) {
        padding: 30px 0 70px;
    }

    >div {
        border: 1px solid black;
        border-radius: 50px;
        padding: 4px 6px;

        &.Choose__Annually {
            button {
                &:first-child {
                    color: $black;
                    background-color: transparent;
                    padding: 0 28px;
                }
                
                &:nth-child(2) {
                    border-radius: 50px;
                    color: #fff;
                    background-color: #47507C;
                    padding: 12px 36px;
                }
            }

            span {
                background-color: $black;
                color: $white;

                &::before {
                    border-color: transparent transparent #000000 transparent;
                }
            }
        }

        button {
            cursor: pointer;
            border: none;
            font-size: 20px;
            font-weight: 500;

             &:first-child {
                border-radius: 50px;
                color: #fff;
                background-color: #47507C;
                padding: 12px 36px;
            }

            &:nth-child(2) {
                color: $black;
                background-color: transparent;
                padding: 0 28px;
            }
        }

        span {
            position: absolute;
            background-color: #D6D2C4;
            color: #65625A;
            border-radius: 10px;
            padding: 4px 12px;
            margin-left: 24px;
            margin-top: 13px;

            @media screen and (max-width: 767px) {
                margin-left: -118px;
                margin-top: 69px;
            }

            &:before {
                position: absolute;
                left: -7px;
                top: 11px;
                content: '';
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 5px 9px 5px;
                border-color: transparent transparent #D6D2C4 transparent;
                transform: rotate(270deg);

                @media screen and (max-width: 767px) {
                    left: 51px;
                    top: -6px;
                    border-width: 0 6px 7px 6px;
                    transform: rotate(0deg);
                }
            }
        }
    }
}


.Plans {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 15px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
    }

    &__Box {
        &:first-of-type {
            background-color: $beige;
            padding: 40px 15px 20px;
        }

        >div {
            min-height: 200px;
            
            +a {
                width: 100%;
    
                @media screen and (max-width: 1200px) {
                    width: auto;
                }
            }
        }

        ul {
            list-style-type: none;
            margin-left: 0;

            li {
                font-style: normal;
                padding: 0 0 10px 27px;
                font-weight: 400;
                font-size: 18px;
                list-style: none;

                &::before {
                    content: '';
                    margin-top: 2px;
                    left: 3px;
                    background-color: transparent;
                    background-image: url('../../../../../src/images/list-icon.png');
                    background-repeat: no-repeat;
                    height: 13px;
                    width: 16px;
                    font-size: 18px;
                    display: inline-block;
                }
            }
        }

        &__BestValue {
            background-color: $dark-yellow;
            padding: 3px 9px;
        }

        button {
            width: 100%;

            @media screen and (max-width: 990px) {
                width: auto;
            }
        }
    }

    &__PremiumBox {
        background-color: $beige;
        padding: 30px 20px 15px;

        >div {
            min-height: 246px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 15px;

            @media screen and (max-width: 990px) {
                grid-template-columns: 1fr;
            }

            .Plans__Box {
                background-color: #FEFEFA;
                padding: 40px 15px 20px;
            }
        }

    }

    &__PlanTitle {
        color: $brick-red;
        font-size: 14px;
        padding-bottom: 15px;
        display: block;
        padding-top: 25px;
        margin-top: 20px;
        border-top: 1px solid rgba(0,0,0,.1);
    }

}
