.c-skeleton {
    &__article-list {
        max-width: 655px;
        margin: 0 auto;
        padding: 31px 0 64px;
        border-top: 1px solid $black;
        position: relative;

        &--mobile {
            padding-bottom: 0;
        }

        &__wrapper {
            width: calc(100% - 223px);

            @include mq('to', 768) {
                width: calc(100% - 117px);
            }

            &--mobile {
                width: calc(100% - 117px);
            }
        }

        &--category {
            height: 25px;
            width: 150px;

            @include mq('to', 768) {
                height: 15px;
                width: 120px;
            }

            &--mobile {
                height: 15px;
                width: 120px;
            }
        }

        &--title {
            height: 40px;
            width: 250px;
            margin: 20px 0;

            @include mq('to', 768) {
                height: 25px;
                width: 170px;
                margin: 10px 0;
            }

            &--mobile {
                height: 25px;
                width: 170px;
                margin: 10px 0;
            }
        }
        /*
        &--title-secondary {
            height: 40px;
            width: 250px;
        }
        */

        &--author {
            height: 25px;
            width: 150px;

            @include mq('to', 768) {
                height: 15px;
                width: 120px;
            }

            &--mobile {
                height: 15px;
                width: 120px;
            }
        }

        &--img {
            width: 223px;
            height: 223px;

            @include mq('to', 768) {
                width: 117px;
                height: 117px;
            }

            &--mobile {
                width: 117px;
                height: 117px;
            }
        }

        &--img, &--author, &--title-secondary, &--title, &--category {
            background-color: rgba($brick-red, 0.15);            
            animation: shimmer 3s linear infinite;            
        }
    }

    &__article-single {

        &--category, &--title, &--author, &--image, &--content div {
            background-color: rgba($brick-red, 0.15);           
            animation: shimmer 3s linear infinite;
        }

        &--category {
            height: 44px;
            max-width: 300px;
        }

        &--title {
            height: 90px;
            max-width: 500px;
        }

        &--author {
            height: 44px;
            max-width: 400px;
        }

        &--image {
            max-width: 558px;
            height: 558px;
            margin: 0 auto;
        }

        &--content {
            max-width: 558px;
            div {
                height: 32px;
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }

    &__audio-book-single {
        padding: 2% 5%; 
        
        @include mq('to', 992) {
            padding: 1% 2%;
        }

        &--top-section, &--image, &--image-book, &--play-btn-mobile, &--play-btn-desktop, &--details, &--summary, &--genre, &--price, &--related-title, &--related-item, &--related-item-book,
        &--spiracle-extras-title, &--spiracle-extras-image, &--spiracle-extras-content, &--title, &--author, &--price-p {
            animation: shimmer 3s linear infinite;
        }

        &--top-section{
            padding: 57px 76px;
            height: 650px;

            @include mq('to', 992) {
                padding: 0;
                height: auto;
            }
        }

        &--top {
            width: 100%;
            height: 450px;

            @include mq('to', 992) {
                display: flex;
                flex-direction: column;
                height: auto;
                padding: 30px;
            }
        }

        &--left {
            position: relative;
            width: 33%;
            max-width: 450px;

            @include mq('to', 992) {
                min-width: 100%;
            }
        }

        &--image-book {
            height: 25vw;
            max-height: 450px;

            @include mq('to', 992) {
                height: 300px;
            }
        }

        &--play-btn-mobile {
            width: 130px;
            height: 50px;
            border-radius: 40px;
            margin: 20px auto 0;

            @include mq('from', 992) {
                display: none;
            }
        }

        &--play-btn-desktop {
            width: 130px;
            height: 50px;
            border-radius: 40px;
            position: absolute;
            bottom: -90px;
            left: 50%;
            transform: translateX(-50%);

            @include mq('to', 992) {
                display: none;
            }
        }

        &--details {
            width: 20%;
            height: 50%;

            @include mq('to', 992) {
                width: 100%;
                height: 100px;
                margin-top: 20px;
            }
        }

        &--summary {
            width: 30%;
            height: 75%;

            @include mq('to', 992) {
                width: 100%;
                height: 150px;
                margin-top: 20px;
            }
        }

        &--genre {
            width: 100px;
            height: 50px;
            margin: 490px auto 0;
            border-radius: 100px;

            @include mq('to', 992) {
                display: none;
            }
        }

        &--price-p {
            max-width: 320px;
            height: 50px;
            margin-top: 490px;

            @include mq('to', 992) {
                display: none;
            }
        }

        &--wrapper {
            height: 156px;
            padding: 20px 12px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &--related-title {
            width: 40%;
            height: 40px;
        }

        &--related-items {
            padding: 1%;
            //min-height: 400px;
        }

        &--related-item {
            height: 500px;
            width: 24%;
            padding: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mq('to', 992) {
                height: 320px;
                width: 49%;

                &:nth-child(3),
                &:nth-child(4) {
                    display: none;
                }
            }

            &--mobile {
                height: 320px;
                width: 49%;
            }
        }

        &--related-item-book {
            width: 24%;

             @include mq('to', 992) {
                width: 49%;

                &:nth-child(3),
                &:nth-child(4) {
                    display: none;
                }
            }
        }

        &--image {
            width: 100%;
            height: 333px;

            @include mq('to', 992) {
                height: 160px;
            }

            &--mobile {
                height: 170px
            }
        }

        &--title {
            width: 90%;
            height: 30px;
        }

        &--author {
            width: 45%;
            height: 30px;
        }

        &--price {
            width: 30%;
            height: 30px;
            margin-bottom: 9px;
        }

        &--spiracle-extras-title {
            width: 47.5%;
            height: 100px;

            @include mq('to', 992) {
                width: 100%;
                height: 50px;
            }
        }

        &--spiracle-extras-image {
            width: 47.5%;
            height: 450px;

            @include mq('to', 992) {
                width: 100%;
                height: 200px;
            }
        }

        &--spiracle-extras-content {
            width: 47.5%;
            height: 450px;

            @include mq('to', 992) {
                width: 100%;
                height: 200px;
            }
        }
    }

    &__audio-book--listing {
        padding: 2% 1% 1%;

        &--filter {
            height: 30px;
            width: 70px;
            animation: shimmer 3s linear infinite;
        }

        &--sort {
            height: 20px;
            width: 70px;
            margin-right: auto;
            animation: shimmer 3s linear infinite;
        }

        &--bookNumber {
            height: 20px;
            width: 50px;
            animation: shimmer 3s linear infinite;
        }

    }

    &__audio-book-edition {
        max-width: 1440px;
        height: 720px;
        animation: shimmer 3s linear infinite;

        @include mq('to', 992) {
            flex-direction: column;
            height: 790px;
            padding-bottom: 30px;
        }

        &--image, &--summary, &--card {
            animation: shimmer 3s linear infinite;
        }

        &--image, &--details {
            width: 50%;
            height: 100%;

            @include mq('to', 992) {
                width: 100%;
                height: 375px;
            }
        }

        &--details {
            @include mq('to', 992) {               
                height: auto;
                margin-top: 30px;
            }
        }

        &--summary {
            width: 80%;
            height: 360px;
            margin: 80px;            

            @include mq('to', 992) {
                width: 90%;
                height: 350px;
                margin: 0 auto 30px;
            }
        }

        &--card {
            width: 60%;
            height: 153px;
            margin: 0 auto;

            @include mq('to', 992) {
                width: 96%;
                margin: 0 auto;
            }
        }
    }

    &__publisher {
        max-width: 1440px;
        padding: 2% 5%; 
        margin-top: 40px !important;

        @include mq('to', 768) {
            margin-top: 90px !important;
        }

        &--chip, &--title, &--about, &--link {
            animation: shimmer 3s linear infinite;
        }

        &--chip {
            width: 110px;
            height: 40px;
            border-radius: 40px;

            @include mq('to', 768) {
                height: 30px;
            }
        }

        &--title {
            max-width: 430px;
            height: 60px;
            margin: 50px auto 60px;

            @include mq('to', 768) {
                height: 40px;
                margin: 40px auto 60px;
            }
        }

        &--about {
            max-width: 800px;
            height: 320px;
        }

         &--link {
            max-width: 200px;
            height: 20px;
            margin: 65px auto 120px;

            @include mq('to', 768) {
                margin: 40px auto;
            }
        }
    }

    &__order-item {
        max-width: 360px;

        &--number, &--date, &--content, &--image, &--title, &--author, &--price {
            animation: shimmer 3s linear infinite;
        }

        &--top {
            padding: 0 15px;
            margin-bottom: 17px;
        }

        &--number {
            width: 130px;
            height: 20px;
        }

        &--date {
            width: 100px;
            height: 20px;
        }

        &--content {
            padding: 5px;
            margin-bottom: 20px;
        }
        
        &--image {
            min-width: 110px;
            height: 110px;
            margin-right: 15px;
        }

        &--title {
            width: 90%;
            height: 20px;
            margin-top: 5px;
        }

        &--author {
            width: 60%;
            height: 20px;
            margin-top: 20px;
        }

        &--price {
            width: 70px;
            height: 20px;
            margin-top: 20px;
        }
    }

    &__subscription {

        &--header, &--button, &--link, &--title, &--subscription {
            animation: shimmer 3s linear infinite;
        }
    
        &--top {
            max-width: 360px;
        }

        &--header {
            width: 181px;
            height: 24px;
        }

        &--buttons {
            max-width: 331px;
            display: flex;
        }

        &--button {
            width: 158px;
            height: 68px;

            &:nth-of-type(2) {
                margin-left: 15px;
            }
        }

        &--link {
            width: 218px;
            height: 22px;
        }

        &--bottom {
            max-width: 611px;
            padding: 0 22px;
        }

        &--title {
            height: 50px;
        }

        &--subscription {
            height: 30px;
        }
    }

    &__subscription-item {
        height: 35px;
        animation: shimmer 3s linear infinite;
    }
}

@keyframes shimmer {
  0%{
    // background-position: -450px 0;
    background-color: rgba($brick-red, 0.05);
  }
  50%{
    // background-position: 450px 0;
    background-color: rgba($brick-red, 0.25);
  }
  100% {
    background-color: rgba($brick-red, 0.05);
  }
}