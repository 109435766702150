.c-dropdown {
    &--toggle {
        color: $black;

        &:hover {
            cursor: pointer;
        }
    }

    ul {
        list-style-type: none;
        margin: 10px 0 10px 10px;
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;

        &.dropdown-open {
            max-height: 1000px;
            visibility: visible;
            opacity: 1;
        }
    }
}