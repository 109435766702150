@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?d3ctdw');
  src:  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?d3ctdw#iefix') format('embedded-opentype'),
    url('../#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?d3ctdw') format('truetype'),
    url('../#{$icomoon-font-path}/#{$icomoon-font-family}.woff?d3ctdw') format('woff'),
    url('../#{$icomoon-font-path}/#{$icomoon-font-family}.svg?d3ctdw##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: "\e929";
}
.icon-twitter-new:before {
  content: "\e928";
}
.icon-linkedin:before {
  content: "\e927";
}
.icon-calendar:before {
  content: "\e926";
}
.icon-left-arrow:before {
  content: "\e925";
}
.icon-discount:before {
  content: "\e922";
}
.icon-discount-2:before {
  content: "\e923";
}
.icon-discount-3:before {
  content: "\e924";
}
.icon-gift:before {
  content: "\e920";
}
.icon-check-circle:before {
  content: "\e921";
}
.icon-checked:before {
  content: "\e91e";
}
.icon-not-checked:before {
  content: "\e91f";
}
.icon-sleep-active:before {
  content: "\e91d";
}
.icon-dots:before {
  content: "\e91c";
}
.icon-chapters:before {
  content: "\e919";
}
.icon-book-page:before {
  content: "\e91a";
}
.icon-sleep:before {
  content: "\e91b";
}
.icon-search:before {
  content: "\e900";
}
.icon-account:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-audio-book:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-close-circle:before {
  content: "\e906";
}
.icon-close-small:before {
  content: "\e907";
}
.icon-download:before {
  content: "\e908";
}
.icon-e-book:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-google:before {
  content: "\e90b";
}
.icon-hamburger:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90d";
}
.icon-library:before {
  content: "\e90e";
}
.icon-pinterest:before {
  content: "\e90f";
}
.icon-shopping-bag:before {
  content: "\e910";
}
.icon-twitter:before {
  content: "\e911";
}
.icon-login:before {
  content: "\e912";
}
.icon-external:before {
  content: "\e913";
}
.icon-play:before {
  content: "\e914";
}
.icon-arrow-down:before {
  content: "\e915";
}
.icon-eye:before {
  content: "\e916";
}
.icon-plus:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e918";
}
