.c-back-button {
    border: none;
    outline: none;
    background: none;
    width: max-content;
    margin-left: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    font-family: $font-secondary;
    font-weight: 500;
    cursor: pointer;
    transition: color .2s ease-in-out;

    @media (hover: hover) {
        &:hover {
            color: $brick-red;
        }
    }

    .icon-left-arrow {
        pointer-events: none;
        transition: none;
    }
}