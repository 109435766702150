.c-member-only {
    padding-top: 100px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        height: 260px;
        width: 100%;
        top: -260px;
        background: linear-gradient(to bottom, #f4eedc 0.52%, rgba(244, 238, 220, 0) 99.38%);
        transform: rotate(-180deg);
    }

    @include mq('to', 768) {
        padding-top: 15px;
    }

    &__title {
        font-size: 48px;
        line-height: 55px;
        letter-spacing: 0.02em;
        margin-bottom: 38px;

        @include mq('to', 768) {
            font-size: 28px;
            line-height: 32px;
            max-width: 335px;
            margin: 0 auto 31px;
        }
    }

    &__image {
        margin-bottom: 48px;

        @include mq('to', 768) {
            margin-bottom: 0;
        }
    }

    &__button {
        width: 100%;
        max-width: 320px;

        &--fixed {
            @include mq('to', 768) {
                position: fixed;
                bottom: 70px; // comment - mobile toolbar height 70
                background: $yellow;
                left: 50%;
                transform: translateX(-50%);
                z-index: $zIndexFixBtn;
                width: calc(100% - 40px);
            }
        }
    }

    &__link {
        margin-bottom: 50px;

        @include mq('to', 768) {
            margin-bottom: 39px;
        }
    }

    &__offers {
        font-size: 24px;
        margin-bottom: 67px;

        @include mq('to', 768) {
            margin-bottom: 45px;
        }
    }

    #c-member-only__parag24 {
         @include mq('to', 768) {
            line-height: 24px !important;
        }
    }

    &__members-recieve {
        margin-bottom: 71px;

        h3 {
            font-size: 18px;
            letter-spacing: 0.15em;
            margin-bottom: 42px;

            @include mq('to', 768) {
                font-size: 12px;
                margin-bottom: 22px;
            }
        }
    }

    &__subscribers-recieve {
        margin-bottom: 83px;

        h3 {
            font-size: 18px;
            letter-spacing: 0.15em;
            margin-bottom: 42px;

            @include mq('to', 768) {
                font-size: 12px;
                margin-bottom: 22px;
            }
        }
    }
}
