.c-error-message {
    font-size: 12px;
    font-family: $font-secondary;

    div {
        font-style: italic;
        color: $error-red;
        margin: 0 0 1px 12px;
    }

    &__first-letter {
        &::first-letter {
            text-transform: uppercase;
        }
    }

    span {
        margin-left: 12px;
    }
}
