.c-list {
    &--ebooks {
        margin: 19px 0 24px;
        list-style: none;
    }

    &--collection {
        align-items: flex-end;
        margin-bottom: 21px;
    }

    &--ordered {
        margin-left: 40px;

        @include mq('to', 768) {
            margin-left: 20px;
        }

        li {
            list-style-type: auto;
        }
    }

    &--none {
        list-style-type: none;

        li {
            a {
                i {
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                a {
                    i {
                        transform: translateX(5px);
                        color: $brick-red;
                    }
                }
            }
        }
    }

    &--reseller {
        li {
            font-size: 18px !important;
            line-height: 25px !important;
        }
    }
}

.c-info-page__content,
.c-faq__item--answer {
    ul {
        list-style-type: none;
        margin-left: 35px;

        li {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 17px;
                left: -18px;
                height: 4px;
                width: 4px;
                background-color: $black;
                border-radius: 50%;
            }
        }
    }
}
