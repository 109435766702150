.c-info-page {
    margin-top: -35px;

    @include mq("to", 768) {
        margin-top: -80px;
    }
    
    &__header {
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
    }

    &__title {
        font-weight: 400;
        border-bottom: 1px solid $black;
        margin-top: 8px !important;

        @include mq("to", 768) {
            font-size: 16px !important;
            margin-top: -2px !important;
            padding-bottom: 0 !important;
        }
    }

    &__updated-at {
        margin-bottom: 13px !important;
        letter-spacing: 0.01em;
        margin-top: 8px !important;

        @include mq("to", 768) {
            margin-bottom: 9px !important;
            margin-top: 5px !important;
        }
    }

    &__updated-at,
    &__tabs {
        @include mq("to", 768) {
            font-size: 16px !important;
        }
    }

    &__tabs {
        max-height: 34px;
        position: relative;
        margin-top: 8px;
        margin-bottom: 13px;
        
        @include mq("to", 768) {
            margin-top: 2px !important;
            margin-bottom: 4px !important;
        }

        span {
            transition: color 0.3s ease-in-out;

            &:hover {
                cursor: pointer;
                color: $brick-red;
            }

            &.active {
                color: $brick-red;
            }

            @include mq("to", 768) {
                font-size: 16px;
            }
        }

        &.shadow {
            &::before,
            &::after {
                content: "";
                height: 100%;
                position: absolute;
                width: 50%;
                background: linear-gradient(-90deg, #f4eedc 5%, rgba(244, 238, 220, 0) 67.78%);
                z-index: 10;
                pointer-events: none;
            }

            &::before {
                top: 0;
                right: 0;
            }

            &::after {
                top: 0;
                left: 0;
                transform: rotateZ(180deg);
            }
        }
    }

    &__content {
        p,
        div,
        ul {
            margin-bottom: 22px;

            @include mq("to", 768) {
                font-size: 16px !important;
                margin-bottom: 20px;
                line-height: 22px !important;
            }
        }

        // 4ae60d3 - iframe 100% width
        // .embeddedContent {
        //     iframe {
        //         width: 100%;
        //     }
        // }

        iframe {
            width: 100%;
        }

        ul {
            li {
                &::before {
                    top: 15px !important;
                    left: -17px !important;
                }
            }
        }       

        // p {
        //     &[style="margin-left: 120px;"] {
        //         @include mq("to", 768) {
        //             margin-left: 0 !important;
        //         }

        //         img {
        //           @include mq("to", 768) {
        //                 width: 100% !important;
        //                 height: auto !important;
        //             }  
        //         }
        //     }
        // }
        
        h3 {
            &:has(a) {
                margin-bottom: 20px;

                @include mq("to", 768) {
                    margin-bottom: 5px;
                }
            }

            a {
                font-size: 24px;

                @include mq("to", 768) {
                    font-size: 16px;
                }
            }
        }

        a {
            &.mobile-50 {
                @include mq("to", 768) {
                    max-width: 50%;
                    
                    img {
                        height: auto !important;
                    }
                }
            }
        }

        img {
            &.mobile-50 {
                @include mq("to", 768) {
                    max-width: 50%;
                    height: auto !important;
                }
            }
        }
    }
}
