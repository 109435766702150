.c-login {
    &__heading {
        margin-bottom: 72px;
        font-size: 16px;
        line-height: 1 !important;
    }

    @include mq("to", 768) {
        &__heading {
            margin-top: -35px !important;
            margin-bottom: 30px;
            font-size: 12px !important;

            span {
                display: block;
            }
        }
    }
}
