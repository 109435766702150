.c-cookie-banner {
    padding: 25px 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    background-color: $light-yellow;
    z-index: $zModal;
    text-align: center;
    transform: translateY(110%);
    transition: transform .3s ease-in-out;

    @include mq('to', 768) {
        padding: 20px 20px 30px;
    }

    &--button {
        width: 320px;

        @include mq('to', 400) {
            width: 100%;
        }
    }

    &--text {
        max-width: 811px;
        letter-spacing: 0.02em;
        line-height: 18px;

        @include mq('to', 768) {
            max-width: 300px;
        }
    }

    &.visible {
        transform: translateY(0);
    }
}