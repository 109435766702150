.Page500 {
    max-width: 550px;
    margin: 130px auto 0;
    padding: 0 15px;

    h1 {
        @media screen and (max-width: 768px) {
            max-width: 260px;
            margin-left: auto;
            margin-right: auto;
            line-height: 1.3;
        }
    }

    img {
        width: 100%;
        max-width: 300px;
    }

    a {
        text-decoration: underline;
    }
}
