.c-btn {
    border-radius: 23px;
    padding: 12px 16px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    text-decoration: none;
    position: relative;
    background-color: transparent;
    min-width: 100px;
    font-weight: 400;

    svg {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled ,
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: text;
    }

    &__menu-link {
        padding: 0;
    }

    &__ghost {
        color: $black;
        border: 1px solid $black;
        background-color: transparent;
        padding: 5px 16px 11px;
        line-height: 20px;

        .c-list & {
            min-width: auto;
        }

        &:hover {
            color: $mine-shaft;
            background-color: transparent;
            border: 1px solid $mine-shaft;
        }

        &--square,
        &--cancel {
            padding: 12px 16px;
            border-radius: 0;
            font-size: 12px;
            font-style: normal;
        }

        &--square {
            padding: 12px 10px;

            &--full {
                color: $yellow;
                background-color: $black;

                &:hover {
                    color: $light-yellow;
                    background-color: $mine-shaft;
                }
            }
        }

        &--cancel {
            border: 1px solid $error-red;
            color: $error-red;
        }

        &--saved {
            border-color: transparent;
            background-color: rgba($black, 0.5);
            color: $yellow;

            &:hover {
                pointer-events: none;
                border-color: transparent;
                background-color: rgba($black, 0.5);
                color: $yellow;
            }
        }

        &--error {
            border-color: transparent;
            background-color: rgba($brick-red, 0.5);
            color: $yellow;

            &:hover {
                pointer-events: none;
                border-color: transparent;
                background-color: rgba($brick-red, 0.5);
                color: $yellow;
            }
        }

        &--active {
            background-color: $black;
            color: $lighter-yellow;

            &:hover {                
                background-color: $black;
                color: $lighter-yellow;

                i {
                    color: $lighter-yellow;
                }
            }
        }

        i {
            &:hover {
                color: $mine-shaft;            
            }
        }
    }

    &__black {
        color: $yellow;
        border: 1px solid $black;
        background-color: $black;

        &--italic {
            padding: 8px 17.36px 12px;
            line-height: 22px;
        }

        &:hover {
            background-color: $mine-shaft;
        }
    }

    &__brown {
        color: $yellow;
        border: 1px solid $brick-red;
        background-color: $brick-red;
        line-height: 1;
        padding: 12px 15.9px 10px;

        &:hover {
            background-color: lighten($brick-red, 5%);
        }
    }

    &__blue {
        color: $yellow;
        border: 1px solid #323853;
        background-color: #323853;
        line-height: 1;
        padding: 12px 15.9px 10px;

        &:hover {
            background-color: lighten(#323853, 5%);
        }
    }

    &__link {
        border: none;
        padding: 0;
        position: relative;
        display: inline-block;
        color: $black;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0px;
            height: 1px;
            width: 0;
            background-color: $black;
            transition: width 0.3s ease-in-out;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }

        &--forgot-password {
            @include mq('to', 768) {
                margin-bottom: 16px !important;
            }
        }
    }

    &__pagination {
        margin-top: 91px;

        @include mq('to', 768) {
            margin-top: 31px;
        }
    }

    &.submitting {
        color: transparent;

        svg {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.sa-button {
    text-decoration: none !important;
    border: 1px solid;
    padding: 10px 45px 15px !important;
    width: max-content !important;
    max-width: 100% !important;
    font-size: 20px !important;
    line-height: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-family: "Gill Sans Nova" !important;
    color: $yellow !important;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out !important;

    &--svg {
        svg {
            display: none;
        }

        &.submitting {
            position: relative;
            color: transparent !important;

            svg {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &:hover {
        color: $yellow !important;
    }
    &::before {
        display: none;
    }
}

.sa-button-small {
    padding: 12px 27px 13px !important;
    font-size: 12px !important;
}

.sa-button-rounded {
    border-radius: 50px !important;
}

.sa-button-black,
.sa-button-red,
.sa-button-green,
.sa-button-blue,
.sa-button-ghost {
    @extend .sa-button;
}

.sa-button-black {
    background: $black;
    border-color: $black;

    &:hover {
        background-color: $mine-shaft;
    }
}

.sa-button-red {
    background: $brick-red;
    border-color: $brick-red;

    &:not([disabled]) {
        cursor: pointer;
        
        &:hover {
            background-color: lighten($brick-red, 5%);
        }
    }
}

.sa-button-green {
    background: #215434;
    border-color: #215434;

    &:hover {
        background-color: lighten(#215434, 5%);
    }
}

.sa-button-green-bg {
    background: #215434;
    border-color: #215434;
    color: $white;

    &:hover {
        background-color: lighten(#215434, 5%);
        border-color: lighten(#215434, 5%);
        color: $white;
    }
}

.sa-button-blue {
    background: #323853;
    border-color: #323853;

    &:hover {
        background-color: lighten(#323853, 5%);
    }
}

.sa-button-ghost {
    border-color: $black;
    color: $black !important;

    &:hover {
        color: $mine-shaft !important;
    }
}

.new-button-red {
    cursor: pointer;
    background: #700000;
    border-color: #700000;
    text-decoration: none;
    border: 1px solid #700000;
    border-radius: 50px;
    padding: 7px 30px 12px 30px;
    width: max-content;
    max-width: 100%;
    font-size: 20px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    font-family: "Gill Sans Nova";
    color: #ffffff;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;

    &:hover {
        background-color: #4b0303;
        border-color: #4b0303;
    }
}

.transparent-button {
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    font-family: "Gill Sans Nova";
    height: 40px;
    width: auto;
    line-height: 32px;
    -webkit-letter-spacing: 0.02em;
    -moz-letter-spacing: 0.02em;
    -ms-letter-spacing: 0.02em;
    letter-spacing: 0.02em;
    color: #000000;
    background-color: transparent;
    border: 1px solid #000000;
    padding: 0 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease border 0.3s ease-in-out;
    text-align: center;

    &:hover {
        color: #700000;
        border-color: #700000;
    }
}

.link-button {
    position: relative;
    cursor: pointer;
    font-family: "Gill Sans Nova";
    border: none;
    color: #000000;
    font-style: italic;
    background-color: transparent;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
    width: max-content !important;
    
    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        bottom: -1px;
        right: 0;
        background-color: #000;
        transform: scaleX(1);
        transform-origin: top left;
        transition: transform 0.3s ease;
    }
    
    &:hover::before {
        transform: scaleX(0);
    }
}