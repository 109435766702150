.c-pagination {
    margin-top: 35px;

    @include mq('from', 768) {
        margin-top: 45px;
    }

    &__list {
        margin-left: 0 !important;
        display: flex;
        justify-content: center;
       
        @include mq('from', 768) {
            gap: 16px;
        }

        li {
            cursor: pointer;

            &.previous,
            &.next {
                text-transform: uppercase;

                &.disabled {
                    visibility: hidden;
                }

                a {
                    width: initial;
                    height: initial;

                    @include mq('to', 768) {
                        font-size: 0;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    i {
                        font-size: 11px;
                        font-weight: 600;
                        margin-top: 2px;
                        pointer-events: none;
                        transition: none;
                    }
                }
            }

            &.previous {
                margin-right: auto;

                i {
                    transform: rotate(180deg);
                }
            }

            &.next {
                margin-left: auto;
            }

            &.selected {
                a {
                    background: $gray;
                    color: $white;
                    transition: none;

                    @media (hover: hover) {
                        &:hover {
                            background: $black;
                            color: $white;
                            transition: background .2s ease-in-out;
                        }
                    }
                }
            }

            &::before {
                display: none;
            }

            a {
                display: block;
                width: 38px;
                height: 38px;
                text-align: center;
                line-height: 38px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                font-family: $font-secondary;
                color: $gray;
                transition: none;

                @media (hover: hover) {
                    &:hover {
                        transition: color .2s ease-in-out;
                        color: $brick-red;
                    }
                }

                @include mq('from', 768) {
                    width: 42px;
                    height: 42px;
                    line-height: 42px;
                    font-size: 16px;
                }
            }
        }
    }
}