.h-flex { display: flex }
.h-flex-between {justify-content: space-between !important;}
.h-flex-around {justify-content: space-around;}
.h-flex-center { justify-content: center; }
.h-flex-justify-start { justify-content: flex-start; }
.h-flex-justify-end { justify-content: flex-end; }
.h-flex-middle { align-items: center; }
.h-flex-align-start { align-items: flex-start; }
.h-flex-align-end { align-items: flex-end; }
.h-flex-wrap { flex-wrap: wrap; }
.h-flex-row { flex-direction: row; }
.h-flex-column { flex-direction: column; }