.c-faq {
    &__item {
        &--question {
            transition: color 0.3s ease, transform 0.3s ease;
            position: relative;

            @include mq('to', 768) {
                font-size: 16px;
                padding-right: 20px;
            }

            i {
                position: absolute;
                right: 0;
                top: 5px;
            }

            &:hover {
                cursor: pointer;
                color: $brick-red;

                i {
                    cursor: pointer;
                    color: $brick-red;
                }
            }

            &.open {
                i {
                    transform: rotateZ(90deg);
                }
            }
        }

        &--answer {
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;
            transition: max-height 0.3s ease, margin-bottom 0.3s ease;
            width: 90%;

            @include mq('to', 768) {
                font-size: 16px;
            }

            &.open {
                max-height: 1000px;
                margin-bottom: 22px;
            }
        }
    }
}
