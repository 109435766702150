@import "../../../../scss/variables";

.GiftMembership {
    background-color: $dark-yellow;
    flex-direction: column;
    align-items: center;
    padding: 70px 130px;

    @media screen and (max-width: 768px) {
        padding: 60px 0;
    }

    &__Content {
        flex-direction: row;
        text-align: center;
        gap: 20px;
        padding-bottom: 40px;

        @media screen and (max-width: 990px) {
            flex-direction: column;
        }

        >div {
            flex: 1;
            max-width: 342px;
        }

        p {

            &:first-of-type {
                display: inline-block;
                padding: 3px 11px;
                border: 1px solid $black;
                border-radius: 50%;
            }
        }

        +a {
            padding: 10px 20px;
            border: 1px solid $brick-red;
            height: 53px;
            width: 298px;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}