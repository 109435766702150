.parsed-body {
    font-size: 24px;
    line-height: 32px;

    @include mq('from', 768) {
        line-height: 35px;
    }

    &.summary {
        font-size: 32px;
        line-height: 42px;
    }

    p {
        @include mq('to', 768) {
            line-height: 24px !important;
        }
    }

    ol {
        margin: 0 0 32px 30px;

        li {
            &::marker {
                font-style: italic;
            }
        }
    }

    ul {
        list-style-type: none;
        margin: 0 0 32px 30px;
    
        li {
            position: relative;
    
            &::before {
                content: '';
                position: absolute;
                top: 17px;
                left: -18px;
                height: 4px;
                width: 4px;
                background-color: $black;
                border-radius: 50%;

                @include mq('to', 768) {
                    top: 8px;
                    height: 3px;
                    width: 3px;
                }
            }
        }
    }

    .twitter-tweet {
        //need to override twitter inline style
        margin: 0 auto 32px !important;
    }

    img {
        width: 100% !important;
        height: auto !important;
    }

    iframe {
        margin: 0 auto;
    }

    hr {
        border-top: 1px solid $black;
        margin-bottom: 1em;
    }

    h1 {
        font-size: 2em !important;
    }

    h2 {
        font-size: 1.5em;

    }

    h3 {
        font-size: 1.17em;
    }

    h4 {
        font-size: 1em;
    }

    h5 {
        font-size: 0.83em;
    }

    h6 {
        font-size: 0.67em;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        line-height: 1.2em;
    }

    @include mq('to', 768) {
        font-size: 18px;
        line-height: 24px !important;
    }
}

.video-player {
    iframe {
        margin: 0 auto;
        border: none;
    }
}