input, textarea {
    background-color: transparent;
    color: $black;
    font-size: 16px;
    font-family: $font-secondary;
    border: none;
    border-bottom: 1px solid $black;
    height: 46px;
    width: 100%;
    letter-spacing: 0.04em;
    padding: 15px 15px 15px 0px;
    transition: all .3s ease-in-out;

    &::placeholder {
        font-size: 12px;
        font-family: $font-secondary;
        color: $black;
        letter-spacing: 0.04em;
    }

    &.bg-white {
        background-color: #fffff3;
        color: $black;
        padding: 15px;
        border: none;
        border-bottom: 1px solid transparent;
        
        &.invalid {
            background: rgba(255, 0, 0, 0.08);
            border: 1px solid rgba(255, 0, 0, 0.63);
            color: $error-red;
        }

        &:focus {
            border-bottom: 1px solid $black !important;
            border-top: none;
            border-left: none;
            border-right: none;
            background-color: #fffff3;
            color: $black;
        }

        &::placeholder {
            color: $light-gray;
            font-size: 16px;
            letter-spacing: normal;
        }
    }
}

textarea {
    resize: none;
    height: auto;
}

input:focus-visible,
textarea:focus-visible {
    border: none;
    outline: none;
    border-bottom: none;
}

input:-webkit-autofill {
    background-color: #fffff3;
}
input:autofill {
    background-color: #fffff3
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
 -webkit-box-shadow: 0 0 0 30px #fffff3 inset !important;
}

.input-description {
    font-family: $font-secondary;
    margin-top: 8px;
    color: #B0B0B0;
    font-size: 12px;
}